<template>
  <div class="animated fadeIn container">
    <div class="card p-4" style="width:100%;margin:0 auto;max-width: 600px">
      <h2>헬로문자 충전</h2>
      <p class="pt-2 color-flatwhite">농협은행 / 317-0014-9802-11 (예금주: 주식회사 네온플라이)</p>

      <p class="text-center color-skyblue my-4" style="font-size:30px">{{ taxedMoney | numberFormat }}원</p>

      <b-input-group>
        <b-input-group-prepend>
          <b-input-group-text>
            <i class="icon-user"></i>
          </b-input-group-text>
        </b-input-group-prepend>
        <b-form-input
          type="text"
          class="form-control"
          placeholder="입금자명"
          autocomplete="depositor"
          v-model="depositor"
        />
      </b-input-group>
      <b-btn variant="primary" class="mt-4" @click.prevent="createDepositRequest">확인</b-btn>
      <b-btn variant="secondary" class="mt-3" @click.prevent="$router.back()">취소</b-btn>
    </div>
  </div>
</template>
<script>
import DepositService from '@/services/DepositService'

export default {
  data() {
    return {
      money: 10000,
      prefixMoneys: [10000, 30000, 50000, 100000, 300000, 500000, 1000000, 2000000],
      depositor: '',
    }
  },
  mounted() {
    this.money = this.$route.query.money
    document.getElementsByClassName('app-body')[0].classList.add('bg-white')
  },
  methods: {
    async createDepositRequest() {
      if (this.depositor.length == 0) {
        alert('입금자명을 입력해주세요')
        return
      }

      const response = await DepositService.createRequest({
        money: this.taxedMoney,
        depositor: this.depositor,
      })

      if (response.status === 200) {
        alert('충전 요청 성공')
        this.$router.push('/deposit/history')
      }
    },
  },
  computed: {
    taxedMoney() {
      return parseInt(parseFloat(this.money) * 1.1)
    },
  },
  watch: {
    money: function(newMoney) {
      if (this.prefixMoneys.indexOf(parseInt(newMoney)) == -1) {
        alert('잘못된 호출입니다')
        this.$router.push('/deposit')
      }
    },
  },
}
</script>

<style scoped>
.button-container {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding: 15px 0;
}

.button-container > div {
  display: flex;
  align-items: center;
}

.button-list {
  list-style: none;
  width: 100%;
  line-height: 20px;
}

.button-list > li {
  float: left;
  color: white;
  position: relative;
  width: 23%;
  margin-left: 2%;
  padding: 4px 0;
  margin: 3px 1%;
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  letter-spacing: -0.5px;
  height: 28px;
  overflow: auto;
  min-width: 70px;
}

.deposit-btn {
  margin-left: 50%;
  transform: translateX(-50%);
  padding: 10px 20px;
}
</style>
